import * as React from "react"
import { useState, useEffect } from "react";
import Layout from "../components/layout"
import Seo from "../utils/seo"
import { Link } from "gatsby";


function NotFoundPage() {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace('/');
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

return (
  <Layout>
    <div className="not-found-container not-found-title">
    Sorry, 404 Not Found<br></br>頁面好似唔見咗
    </div>
    <div className="not-found-container">
    <Link to="/" className="not-found-counter">{counter}秒後回到主頁</Link>
    </div>
  </Layout>
)
}
export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
